<template>
  <v-container>
    <v-toolbar flat>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        hint="Search (name or full id number)"
        persistent-hint
        single-line

        clearable
      />
      <v-spacer/>
      <!--      <v-checkbox-->
      <!--          v-model="showImages"-->
      <!--          label="Only Images"-->
      <!--          single-line-->
      <!--          hide-details-->
      <!--          disabled-->
      <!--      />-->
      <v-select
        multiple
        :items="Object.values(diets||{})"
        v-model="filterDiets"
        hint="Filter Diet/Stream"
        single-line
        persistent-hint
        item-value="name"
        item-text="stream"
        clearable
      />
      <v-spacer/>
      <v-select
        multiple
        :items="'packaged,plated,closeup'.split(',')"
        v-model="filterMissingTypes"
        hint="Filter Missing"
        persistent-hint
        single-line
        clearable
      />

    </v-toolbar>
    <v-data-table
      :search="search"
      :headers="headers"
      :items="filteredMeals"
      sort-by="id"
      :sort-desc="true"
      :loading="loading"
    >
      <template v-slot:item.sku="{ item }">
        <v-btn icon shaped :class="{ yellow : item.is_selected}" class="mr-2" disabled>
          <v-icon>mdi-star</v-icon>
        </v-btn>
        <MealSkuLink :sku="item.sku"/>
      </template>
      <template v-slot:item.images="{ item }">
        <v-row>
          <v-col v-if="item.noImages">
            <router-link :to="{name:'MealImages', params: { id: item.id}}">Add Images</router-link>
          </v-col>
          <v-col v-for="(i,key) of item.images"
                 v-bind:key="i.url">
            <router-link :to="{name:'MealImages', params: { id: item.id}}">
              {{ key }}
              <v-img
                max-height="50"
                max-width="50"
                :src="i.url"
                :alt="key"
              />
            </router-link>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.sku_images="{ item }">
        <v-row v-if="item.sku_images">
          <v-col v-for="(i,key) of item.sku_images"
                 v-bind:key="i.url">
            <router-link :to="{name:'MealImages', params: { id: item.id}}">
              {{ key }}
              <v-img
                max-height="50"
                max-width="50"
                :src="i.url"
                :alt="key"
              />
            </router-link>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>


<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {dateFormatted} from '@/store/utils';
import api from '@/api';
import MealSkuLink from "@/components/MealSkuLink";
import urlState from "@/router/urlState";

export default {
  components: {MealSkuLink},
  mixins: [urlState],
  data() {
    return {
      readOnly: true,
      loading: false,
      loadingPromise: undefined,
      mealMap: {},
      meals: [],
      headers: [
        {text: 'Meal Id', value: 'id', width: 100},
        {text: 'SKU', value: 'sku', width: 180},
        {text: 'name', value: 'name'},
        {text: 'diet', value: 'diet'},
        {text: 'date', value: 'date'},
        {text: 'meal version images', value: 'images'},
        {text: 'sku images', value: 'sku_images'},
      ],
      search: null,
      showImages: true,
      filterMissingTypes: null,
      filterDiets: null,
    }
  },
  created() {
    this.fetchDiets().then((d) => {
      console.log('fetched diets',d);
      this.fetchData();
    });
    this.readOnly = !!this.$route.meta.readOnly;
    this.syncToUrl({
      param: 'filterMissingTypes', urlParam: 'need', initFromRoute: true,
      // parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'filterDiets', urlParam: 'diets', initFromRoute: true,
    })
    this.syncToUrl({
      param: 'search', urlParam: 'search', initFromRoute: true,
      // parseCallback: (v) => Number(v)
    })
  },
  watch: {
// // call again the method if the route changes
//     '$route.params.date': 'fetchData',
//     '$route.query.view'(val) {
//       this.view = val;
//     }
  },
  computed: {
    ...mapState(['diets']),
    ...mapGetters(['getDietName']),
    filteredMeals() {
      let meals = this.meals;
      if (this.filterDiets && this.filterDiets.length>0){
        console.log('fitlering diets', this.filterDiets, meals[0] && meals[0])
        meals = meals.filter(m => this.filterDiets.includes(m.diet))
      }
      if (this.filterMissingTypes && this.filterMissingTypes.length > 0) {
        meals = meals.filter(m => !m.images || !this.filterMissingTypes.every(type => !!m.images[type]));
      }
      return meals;
    }
  },
  methods: {
    ...mapActions(['fetchDiets']),
    fetchData(from = 0) {
      const where = {
        // name: {
        //   contains: query,
        //   mode: 'insensitive',
        // },
        // date: {
        //   not: null
        // }
        meal_photos: {some: {}}
      };
      if (from === 0) {
        this.meals = [];
        this.loading = true;
      }
      console.log('fetching', {from});
      return api
        .get(`/v2/meal`, {
          params: {
            from,
            // limit,
            // sortBy,
            // sortDesc,
            orderBy: [{date: 'desc'}, {id: 'asc'}],
            where,
            minimal: true
          }
        })
        .then(({data}) => {
          const {count, meals} = data;
          console.log('got data', {count, meals});
          // console.log('first', JSON.stringify(meals[0]));
          // console.log('last', JSON.stringify(meals[meals.length - 1]));

          meals.forEach(meal => {
            const existing = this.mealMap[meal.id];
            if (existing) {
              console.log('dupe', {existing, meal})
            } else {
              this.mealMap[meal.id] = meal;
              if (Object.keys(meal.images || {}).length === 0) {
                delete meal.images;
                meal.noImages = true;
              }
              meal.diet = this.getDietName(meal.diet);
              this.meals.push(meal);
            }
          })
          if (meals.length > 0 && this.meals.length < count) {
            return this.fetchData(from + meals.length);
          }
        })
        .finally(() => {
          this.loading = false;
        })
    },
    dateFormatted,
  }
}
</script>
